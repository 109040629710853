import React, { FC, MouseEventHandler } from "react";
import ModalContainer, { Variant } from "components/molecules/ModalContainer";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import S from "./styles";

interface OptionProps {
  value: boolean;
  onToggle: () => void;
  title: string;
}

const Option: FC<OptionProps> = ({ title, value, onToggle }) => (
  <S.Option>
    <S.OptionTitle>{title}</S.OptionTitle>
    <S.ToggleContainer active={value} onClick={onToggle}>
      <S.Toggle active={value} />
    </S.ToggleContainer>
  </S.Option>
);

export interface ExportPdfModalProps {
  expandSections: boolean;
  enablePagination: boolean;
  onToggleExpandSections: () => void;
  onToggleEnablePagination: () => void;
  onCloseModal: () => void;
  onExport: MouseEventHandler;
}

const ExportPdfModal: FC<ExportPdfModalProps> = ({
  expandSections,
  enablePagination,
  onToggleExpandSections,
  onToggleEnablePagination,
  onCloseModal,
  onExport
}) => (
  <ModalContainer
    isOpen
    toggleOpen={onCloseModal}
    onExitClick={onCloseModal}
    variant={Variant.LIGHT}
    fade={false}
    width={471}
    title="Export to PDF"
  >
    <S.DescriptionText>
      The report will be exported with the sections expanded and/or collapsed as
      you see them on the screen now.
    </S.DescriptionText>
    <S.CtaText>
      Would you like to expand all sections before exporting?
    </S.CtaText>
    <S.OptionsContainer>
      <Option
        title="Expand all sections"
        value={expandSections}
        onToggle={onToggleExpandSections}
      />
      <Option
        title="Enable pagination"
        value={enablePagination}
        onToggle={onToggleEnablePagination}
      />
    </S.OptionsContainer>
    <S.Actions>
      <S.CancelButton
        onClick={onCloseModal}
        type={ButtonType.Filled}
        size={ButtonSize.Medium}
        text="Cancel"
      />
      <S.ExportButton
        onClick={onExport}
        type={ButtonType.Filled}
        size={ButtonSize.Medium}
        text="Export"
      />
    </S.Actions>
  </ModalContainer>
);

export default ExportPdfModal;
