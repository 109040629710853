import React from "react";
import { REPORT_TYPES } from "util/reportTypes";
import { SCREENING_LIST_TYPES } from "util/screeningUtils";

/**
 *
 * @param {string} listType - screening risk type, e.g. sanction, watchlist, or pep
 * @param {number} count - number of screening hits
 * @param {string} entityType - organisation or person
 */
export const getConfirmedExplainerText = (listType, count) => {
  const isPlural = count !== 1;

  if (listType === SCREENING_LIST_TYPES.sanctions) {
    if (count === 0) {
      return (
        <div>
          We have <strong>not</strong> identified your subject as{" "}
          <strong>sanctioned</strong>.
        </div>
      );
    }

    return (
      <div>
        We have identified your subject on{" "}
        <strong>
          {count} sanction list
          {isPlural ? "s" : ""}
        </strong>
        .
      </div>
    );
  }
  if (listType === SCREENING_LIST_TYPES.watchlists) {
    if (count === 0) {
      return (
        <div>
          We have <strong>not</strong> identified your subject on any{" "}
          <strong>watchlists</strong>.
        </div>
      );
    }

    return (
      <div>
        We have identified your subject on{" "}
        <strong>
          {count} watchlist
          {isPlural ? "s" : ""}
        </strong>
        .
      </div>
    );
  }
  if (listType === SCREENING_LIST_TYPES.peps) {
    if (count === 0) {
      return (
        <div>
          We have <strong>not</strong> identified your subject as a{" "}
          <strong>Politically Exposed Person (PEP)</strong>.
        </div>
      );
    }

    return (
      <div>
        We have identified your subject as a{" "}
        <strong>Politically Exposed Person (PEP)</strong>.
      </div>
    );
  }

  return null;
};

/**
 *
 * @param {string} listType - screening risk type, e.g. sanction, watchlist, or pep
 * @param {number} count - number of screening hits
 * @param {string} entityType - organisation or person
 */
export const getForReviewExplainerText = (
  listType,
  count,
  entityType,
  hasConfirmedMatches
) => {
  const isPlural = count !== 1;

  let entityTypeString = entityType.toLowerCase();

  if (isPlural) {
    if (entityType === REPORT_TYPES.person) {
      entityTypeString = "people";
    } else if (entityType === REPORT_TYPES.organisation) {
      entityTypeString = "organisations";
    }
  }

  let prefixText = "Xapien also identified";

  // If there is no confirmed matches for the screening risk, the prefix text requires
  // this small change in structure.
  if (!hasConfirmedMatches) {
    prefixText = "However, Xapien identified";
  }

  if (listType === SCREENING_LIST_TYPES.sanctions) {
    return (
      <div>
        {prefixText}{" "}
        <strong>
          {count} sanctioned {entityTypeString}
        </strong>{" "}
        with{" "}
        <strong>{isPlural ? "names" : "a name"} similar to your subject</strong>
        , where there was{" "}
        <strong>
          insufficient information to confirm or discard them as your subject
        </strong>
        . You may wish to review these.
      </div>
    );
  }
  if (listType === SCREENING_LIST_TYPES.watchlists) {
    return (
      <div>
        {prefixText}{" "}
        <strong>
          {count} {entityTypeString}
        </strong>{" "}
        with{" "}
        <strong>{isPlural ? "names" : "a name"} similar to your subject</strong>{" "}
        on <strong>watchlists</strong>, where there was{" "}
        <strong>
          insufficient information to confirm or discard them as your subject
        </strong>
        . You may wish to review these.
      </div>
    );
  }
  if (listType === SCREENING_LIST_TYPES.peps) {
    return (
      <div>
        {prefixText}{" "}
        <strong>
          {count} {entityTypeString}
        </strong>{" "}
        with{" "}
        <strong>
          {isPlural ? "names" : "a name"} similar to your subject{" "}
          {isPlural ? "who are PEPs" : "who is a PEP"}
        </strong>
        , where there was{" "}
        <strong>
          insufficient information to confirm or discard them as your subject
        </strong>
        . You may wish to review these.
      </div>
    );
  }
  return null;
};
