import { styled } from "@linaria/react";
import { grey, purple, red, standardColors } from "styles/colors";
import { fontSize, family } from "styles/fonts";
import { ALIGNMENT_OPTIONS } from "components/organisms/InsightReport/TimelineSection/constants";
import { ReactComponent as TrashSVG } from "img/icons/actiontoolbar/trash.svg";
import { hexToRgb } from "styles/hexToRgb";
import WithInspectorStyles from "components/organisms/WithInspector/styles";

// @ts-ignore;
import theme from "theme";
import { isPDX } from "static-config";

export interface EventContentProps {
  alignment: string;
}
export interface DeleteButtonProps {
  alignment: string;
  nodeSize: number;
}

export interface ContentDivProps {
  alignment: string;
  nodeSize: number;
}

const EventTitle = styled.h2`
  font-size: ${fontSize.lg};
  font-family: ${family.interDisplay};
  display: inline-block;
  margin-bottom: 5px;
`;

const EventSubtitle = styled.p`
  margin-bottom: 0;
  font-size: ${fontSize.sm};
  font-family: ${family.interRegular};
  color: ${grey.mid};
  padding-bottom: 6px;
`;

const DeleteButton = styled.button<DeleteButtonProps>`
  position: absolute;
  padding: 6px;
  justify-content: center;
  text-align: center;
  display: flex;
  background: ${standardColors.white};
  border-radius: 26px;
  box-shadow: ${() => {
    const { r, g, b } = hexToRgb(red.directRiskFill);
    return `0px 0px 4px 0px rgba(${r}, ${g}, ${b}, 0.25)`;
  }};
  border: none;
  opacity: 0;
  z-index: 2;
  left: ${props =>
    props.alignment === ALIGNMENT_OPTIONS.left ? "-14px" : "auto"};
  right: ${props =>
    props.alignment === ALIGNMENT_OPTIONS.left ? "auto" : "-14px"};
  bottom: -8px;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${grey.light};
  }
`;

const DeleteModalEventContent = styled.div`
  font-size: ${fontSize.sm};
  font-family: ${family.interRegular};
  color: ${grey.dark};
  margin-bottom: 6px;
`;

const EventContent = styled(DeleteModalEventContent)<EventContentProps>`
  /* Required for clamping. Right aligned text will otherwise sometimes
  truncate the ellipses */
  padding-right: ${({ alignment }) =>
    alignment === ALIGNMENT_OPTIONS.right ? 0 : 12}px;
  margin-right: ${({ alignment }) =>
    alignment === ALIGNMENT_OPTIONS.right ? 0 : -12}px;
`;

const ContentDiv = styled.div<ContentDivProps>`
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: ${({ alignment, nodeSize }) =>
    alignment === ALIGNMENT_OPTIONS.left ? "0px" : `${nodeSize + 30}px`};
  padding-right: ${({ alignment, nodeSize }) =>
    alignment === ALIGNMENT_OPTIONS.right ? "0px" : `${nodeSize + 30}px`};
  width: 100%;

  ${WithInspectorStyles.InspectorInnerContainer} {
    padding: 10px;
    padding-left: ${({ alignment }) =>
      alignment === ALIGNMENT_OPTIONS.right ? "10px" : "25px"};
    padding-right: ${({ alignment }) =>
      alignment === ALIGNMENT_OPTIONS.right ? "25px" : "10px"};
    &:hover,
    &:focus {
      background-color: ${() => {
        const { r, g, b } = hexToRgb(
          isPDX ? theme.primaryColor : purple.darker
        );
        return `rgba(${r}, ${g}, ${b}, 0.07)`;
      }};
      border-radius: 10px;
      box-shadow: ${`0 0 1px rgba(116, 57, 179, 0.07)`};
      cursor: pointer;
      > [class*="DeleteButton"] {
        opacity: 1;
      }
    }
  }
`;

const SourcesSubtitle = styled.span`
  font-family: ${family.interSemiBold};
  text-transform: lowercase;
`;

const TrashIcon = styled(TrashSVG)<{ disabled: boolean }>`
  width: 18px;
  height: 18px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  color: ${({ disabled }) =>
    disabled ? standardColors.black : red.directRiskFill};
`;

const WrapperForModal = styled.div<EventContentProps>`
  position: absolute;
  right: ${({ alignment }) =>
    alignment === ALIGNMENT_OPTIONS.left ? "auto" : `0`};
`;

const S = {
  EventTitle,
  EventSubtitle,
  EventContent,
  ContentDiv,
  DeleteButton,
  TrashIcon,
  DeleteModalEventContent,
  WrapperForModal,
  SourcesSubtitle
};

export default S;
