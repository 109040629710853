import { styled } from "@linaria/react";
import { motion } from "framer-motion";

import Skeleton from "components/atoms/Skeleton";
import { isPDX } from "static-config";
import { grey, red, purple, standardColors } from "styles/colors";
import { fontSize, family, headingLetterSpacing } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";

// @ts-ignore
import theme from "theme";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const SuggestionCover = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 20px;
  opacity: 1;
  transition: opacity 0.3s;
  background: ${() => {
    const { r, g, b } = hexToRgb(
      isPDX ? theme.primaryColor : purple.darkerLightened
    );

    return `linear-gradient(90deg,rgba(0, 0, 0, 0) 0%,rgba(${r}, ${g}, ${b}, 1) 35%, rgba(${r}, ${g}, ${b}, 1) 100%)`;
  }};
`;

const SuggestedQuestions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const SuggestionSkeleton = styled(Skeleton)`
  &.MuiSkeleton-text {
    transform: none;
    height: 38px;
    padding: 10px 12px;
    flex-basis: calc(50% - 5px);
    border-radius: 8px;
    background: ${() => {
      const { r, g, b } = hexToRgb(isPDX ? theme.primaryColor : purple.dark);
      return `rgba(${r}, ${g}, ${b}, 0.1)`;
    }};
  }
`;

const LoadingAnimation = styled.div`
  width: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 57px;
  padding: 12px 0;
  margin: 0 auto;

  svg path {
    fill: ${isPDX ? theme.primaryColor : purple.darker};
  }
`;

const SuggestionText = styled(motion.div)``;

const Suggestion = styled.button<{ isHovered: boolean }>`
  all: unset;
  position: relative;
  flex-basis: calc(50% - 5px);
  box-sizing: border-box;
  color: ${isPDX ? standardColors.white : purple.darker};
  background: ${isPDX ? theme.primaryColor : purple.darkerLightened};
  font-size: ${fontSize.xs};
  font-family: ${family.interDisplayMedium};
  letter-spacing: ${headingLetterSpacing["2xs"]};
  transition: background-color 0.3s;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;

  > ${SuggestionText} {
    padding: 10px 12px;
  }

  &:not(:focus-visible) {
    outline: none;
  }

  &:hover,
  &:focus {
    color: ${isPDX ? standardColors.white : purple.darker};
    background: ${isPDX ? theme.primaryColor : purple.darkLightened};

    ${SuggestionCover} {
      opacity: 0;
    }
  }

  :disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`;

const NewSuggestionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
`;

const NewSuggestionsButton = styled.button<{ hasError: boolean }>`
  flex: 1;
  padding: 6px;
  background: ${({ hasError }) => {
    const { r, g, b } = hexToRgb(red.directRisk);
    return hasError ? `rgba(${r}, ${g}, ${b}, 0.1)` : "transparent";
  }};
  color: ${({ hasError }) => (hasError ? red.directRisk : grey.dark)};
  border: 0;
  font-family: ${family.interDisplayMedium};
  font-size: ${fontSize.xs};
  letter-spacing: ${headingLetterSpacing.xs};
  transition: all 0.5s ease;
  border-radius: 6px;

  &:focus {
    outline: none;
  }

  &:hover {
    background: ${({ hasError }) => {
      const { r, g, b } = hexToRgb(red.directRisk);
      return hasError ? `rgba(${r}, ${g}, ${b}, 0.1)` : grey.lighterPanel;
    }};
    color: ${({ hasError }) =>
      hasError ? red.directRisk : standardColors.black};
  }

  :disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`;

const S = {
  Container,
  SuggestedQuestions,
  SuggestionCover,
  LoadingAnimation,
  SuggestionSkeleton,
  Suggestion,
  SuggestionText,
  NewSuggestionsButton,
  NewSuggestionsContainer
};

export default S;
