import React, {
  useState,
  useEffect,
  FC,
  MouseEvent,
  MouseEventHandler
} from "react";
import { NavItem } from "reactstrap";

import config from "config";

// @ts-ignore
import theme from "theme";
import { red } from "styles/colors";

import S from "./styles";
import ExportPdfModal from "./ExportModal";
import ExportButtonTooltip from "./Tooltip";

interface Props {
  downloadReady: boolean;
  exporting: boolean;
  isError: boolean;
  onClearError: () => void;
  print: (e: MouseEvent) => void;
  instance: string;
  downloadPdf: () => void;
  expandAllSectionsForExport: () => void;
  onExpandAllSectionsForExport: (v: (v: boolean) => boolean) => void;
  paginationEnabled: boolean;
  onTogglePagination: () => void;
}

const ExportButton: FC<Props> = ({
  downloadReady,
  exporting,
  isError,
  onClearError,
  print,
  instance,
  downloadPdf,
  expandAllSectionsForExport,
  onExpandAllSectionsForExport,
  paginationEnabled,
  onTogglePagination
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (isError || downloadReady) {
      setShowTooltip(true);
    }
  }, [isError, downloadReady]);

  const handleDismissError = () => {
    setShowTooltip(false);
    onClearError();
  };

  const renderExport = () => (
    <>
      {showTooltip && (
        <ExportButtonTooltip title="Export to PDF">
          Click here to export the report to PDF.
        </ExportButtonTooltip>
      )}
      <S.ExportButton id={`ExportButton${instance}`}>
        <S.Download />
      </S.ExportButton>
    </>
  );

  const renderExporting = () => {
    if (!exporting) {
      return null;
    }

    return (
      <>
        {showTooltip && (
          <ExportButtonTooltip title="Export to PDF in progress">
            We&apos;re currently working on your export.
          </ExportButtonTooltip>
        )}
        <S.ExportButton>
          <S.ExportSpinner
            id={`ExportSpinner${instance}`}
            size="m"
            color={theme.primaryColor}
          />
        </S.ExportButton>
      </>
    );
  };

  const renderExportReady = () => {
    if (!downloadReady) {
      return null;
    }

    return (
      <>
        {showTooltip && (
          <ExportButtonTooltip title="Export to PDF complete">
            Click the button below to download your PDF export.
          </ExportButtonTooltip>
        )}
        <S.ExportButton
          invert={downloadReady}
          id={`ExportButton${instance}`}
          backgroundColor={theme.primaryColor}
        >
          <S.DownloadReady backgroundColor={theme.primaryColor} />
        </S.ExportButton>
      </>
    );
  };

  const renderError = () => {
    if (!isError) {
      return null;
    }

    return (
      <>
        <ExportButtonTooltip
          title="We hit a snag with that export"
          onClose={handleDismissError}
          withCloseButton
        >
          Please try refreshing the page. If the issue persists, contact{" "}
          <a href={`mailto:${config.supportEmail}`}>customer support</a> for
          assistance.
        </ExportButtonTooltip>
        <S.ExportButton
          id={`ErrorButton${instance}`}
          backgroundColor={red.directRiskFill}
          invert
        >
          <S.ErrorIcon />
        </S.ExportButton>
      </>
    );
  };

  const toggleModal = () => {
    setIsModalOpen(prev => !prev);
  };

  const handleExport: MouseEventHandler = e => {
    print(e);
    toggleModal();
  };

  return (
    <>
      <NavItem
        role="menuitem"
        onClick={e => {
          if (isError) {
            e.stopPropagation();
            return;
          }

          if (!exporting && !downloadReady) {
            toggleModal();
          }
          if (downloadReady) {
            downloadPdf();
          }
        }}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <S.ExportButtonContainer>
          {renderError() ||
            renderExportReady() ||
            renderExporting() ||
            renderExport()}
        </S.ExportButtonContainer>
      </NavItem>
      {isModalOpen && (
        <ExportPdfModal
          expandSections={!!expandAllSectionsForExport}
          enablePagination={paginationEnabled}
          onToggleExpandSections={() =>
            onExpandAllSectionsForExport((prev: boolean) => !prev)
          }
          onToggleEnablePagination={onTogglePagination}
          onCloseModal={toggleModal}
          onExport={handleExport}
        />
      )}
    </>
  );
};

export default ExportButton;
