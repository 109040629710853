import { createContext } from "react";

import type { AuthenticationState, AuthenticationAction } from "./types";
import { AuthenticationActions, AuthenticationStatus } from "./types";

export const initialState: AuthenticationState = {
  email: "",
  password: "",
  mfaCode: "",
  username: undefined,
  status: AuthenticationStatus.unknown,
  isPermissionsLoaded: false,
  permissions: {
    canInviteUsers: false,
    canInviteAdmins: false,
    canChangeUserRole: false,
    canGetOrganisationUsage: false,
    canExportOrganisationUsage: false,
    canEditMfa: false,
    canGetOrganisation: false,
    canUpdateOrganisation: false,
    canGetOrganisationPreferences: false,
    canUpdateOrganisationPreferences: false,
    canListUsers: false,
    canListGroups: false,
    canCreateGroup: false,
    canListReports: false,
    canStartReports: false,
    canResetPassword: false
  }
};

export const authenticationReducer = (
  state: AuthenticationState,
  action: AuthenticationAction
) => {
  switch (action.type) {
    case AuthenticationActions.verify: {
      return { ...state, status: AuthenticationStatus.verifying };
    }
    case AuthenticationActions.passwordVerified: {
      return {
        ...state,
        status: AuthenticationStatus.passwordVerified,
        mfaStatus: action.mfaStatus
      };
    }
    case AuthenticationActions.updatePermissions: {
      return {
        ...state,
        permissions: action.permissions,
        isPermissionsLoaded: true
      };
    }
    case AuthenticationActions.updateEmail: {
      return { ...state, email: action.email };
    }
    case AuthenticationActions.updatePassword: {
      return { ...state, password: action.password };
    }
    case AuthenticationActions.updateMfaCode: {
      return { ...state, mfaCode: action.mfaCode };
    }
    case AuthenticationActions.authenticate: {
      return { ...state, status: AuthenticationStatus.authenticating };
    }
    case AuthenticationActions.resetState: {
      return {
        ...state,
        password: ""
      };
    }
    case AuthenticationActions.authenticated: {
      return {
        ...state,
        status: AuthenticationStatus.authenticated,
        mfaCode: "",
        email: "",
        password: "",
        mfaRequired: undefined,
        username: action.username
      };
    }
    case AuthenticationActions.unauthenticated: {
      return {
        ...state,
        status: AuthenticationStatus.unauthenticated,
        mfaCode: "",
        error: action.error
      };
    }
    case AuthenticationActions.dismissError: {
      return {
        ...state,
        error: undefined
      };
    }
    case AuthenticationActions.mfaSetup: {
      return {
        ...state,
        status: AuthenticationStatus.mfaSetup
      };
    }
    case AuthenticationActions.fetchLoginInfo:
      return {
        ...state,
        status: AuthenticationStatus.fetchingLoginInfo
      };
    case AuthenticationActions.fetchLoginInfoSuccess:
      return {
        ...state,
        status: !action.loginProvider
          ? AuthenticationStatus.passwordRequired
          : AuthenticationStatus.authenticating,
        loginProvider: action.loginProvider
      };
    default: {
      return state;
    }
  }
};

export const AuthenticationContext = createContext({
  state: initialState,
  dispatch: (_action: AuthenticationAction) => {}
});
