import { styled } from "@linaria/react";

import { grey, standardColors } from "styles/colors";

const Footer = styled.div`
  border-top: 2px solid ${grey.panel};
  height: 40px;
  background-color: ${standardColors.white};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const S = {
  Footer
};

export default S;
