export const SCREENING_LIST_TYPES = {
  watchlists: "watchLists",
  peps: "peps",
  sanctions: "sanctions",
  specialInterest: "specialInterest"
};

export const SCREENING_FILTERS = {
  watchLists: {
    label: "watchlists",
    labelSingular: "watchlist",
    description:
      "Watchlists are databases of individuals known or reasonably suspected of being involved in fraudulent or illegal activities."
  },
  peps: {
    label: "PEPs",
    labelSingular: "PEP",
    description:
      "A PEP (Politically Exposed Person) is an individual entrusted with a prominent public function (or their immediate family)."
  },
  sanctions: {
    label: "sanctions",
    labelSingular: "sanction",
    description:
      "Sanctions are measures imposed by governments and international organisations to restrict an individual’s financial activities."
  },
  specialInterest: {
    label: "Special Interest",
    description:
      "A Special Interest Person encompasses individuals implicated in specific types of criminal activity."
  }
};

export const ORGANISATION_SCREENING_FILTERS = {
  watchLists: {
    label: "watchlists",
    labelSingular: "watchlist",
    description:
      "Watchlists are databases of organisation’s known or reasonably suspected of being involved in fraudulent or illegal activities."
  },
  peps: {
    label: "PEPs",
    labelSingular: "PEP",
    description:
      "A PEP (Politically Exposed Person) is an individual entrusted with a prominent public function (or their immediate family)."
  },
  sanctions: {
    label: "sanctions",
    labelSingular: "sanction",
    description:
      "Sanctions are measures imposed by governments and international organisations to restrict an organisation’s financial activities."
  },
  specialInterest: {
    label: "Special Interest",
    description:
      "A Special Interest Person encompasses individuals implicated in specific types of criminal activity."
  }
};
export const DOW_JONES_ORGANISATION_SCREENING_FILTERS = {
  watchLists: {
    label: "watchlists",
    labelSingular: "watchlist",
    description:
      "Watchlists are databases of organisation’s known or reasonably suspected of being involved in fraudulent or illegal activities."
  },
  peps: {
    label: "PEPs",
    labelSingular: "PEP",
    description:
      "A PEP (Politically Exposed Person) is an individual entrusted with a prominent public function (or their immediate family)."
  },
  sanctions: {
    label: "sanctions",
    labelSingular: "sanction",
    description:
      "Sanctions are measures imposed by governments and international organisations to restrict an organisation’s financial activities."
  },
  specialInterest: {
    label: "Special Interest",
    description:
      "Special Interest Entity focuses companies with a corporate structure and commercial purpose " +
      "that may have had adverse media coverage on ESG related topics."
  }
};

export const MATCH_TYPE_STRINGS = {
  confirmed: "confirmed",
  unconfirmed: "unconfirmed",
  discarded: "discarded"
};

export const isReportVersionTooOldForScreening = (reportVersion?: {
  major: number;
  minor: number;
  patch: number;
}) => {
  // HACK HACK HACK - we have to check on the specific version of the app
  // to determine if we should render the Screening section. Unfortunately,
  // we shipped the backend JSON for Screening prematurely and so we can't
  // rely on just a presence check for the ScreeningSection field in the
  // JSON.
  return (
    !reportVersion || (reportVersion.minor < 21 && reportVersion.major <= 1)
  );
};
