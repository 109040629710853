import React from "react";
import { inject, observer } from "mobx-react";
import { observe } from "mobx";

import { withParams, withNavigate } from "util/routerHocs";

import PreparingReport from "pages/report/PreparingReport";

class PreparingReportContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusColumns: [
        [null, null, null, null],
        [null, null, null, null],
        [null, null, null, null]
      ],
      currentStatusText: ""
    };
    this.observeDisposer = null;
  }

  componentDidMount() {
    const { enquiryStore, params, diagnosticsStore, navigate } = this.props;
    enquiryStore.setCurrentEnquiryId(params.enquiryId);
    this.observeDisposer = observe(diagnosticsStore, change => {
      if (change.name === "enquiryOverview") {
        if (change.newValue && change.newValue.reportReady === true) {
          navigate(`/report/${params.enquiryId}`);
        }
      }
    });

    //* *TODO add some logic in here based on scroll bar position */
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    if (this.observeDisposer) {
      this.observeDisposer();
    }
  }

  render() {
    const { diagnosticsStore, params } = this.props;
    const { statusColumns, currentStatusText } = this.state;
    return (
      <PreparingReport
        messages={diagnosticsStore.messages}
        statusColumns={statusColumns}
        currentStatusText={currentStatusText}
        enquiryId={params.enquiryId}
      />
    );
  }
}

export default withNavigate(
  withParams(
    inject(
      "enquiryStore",
      "diagnosticsStore"
    )(observer(PreparingReportContainer))
  )
);
