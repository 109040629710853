import React, { FC, SVGProps, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import { CloudOff, User, Eye, Trash2, Power } from "react-feather";

import LoadingPage from "components/molecules/LoadingPage";
import Heading from "components/atoms/Heading";
import Input from "components/atoms/Input";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import { routes } from "pages/Router";
import ErrorBanner from "components/atoms/ErrorBanner";
import PageTitle from "components/atoms/PageTitle";
import { Switch } from "@mui/material";
import EnterOrganisationModal from "components/organisms/EnterOrganisationModal";
import { OrganisationSsoStatus } from "api/tenant/types";

import useManageOrganisation, {
  UsageTimePeriod
} from "./useManageOrganisation";

import S from "./styles";

const ORG_NAME_LIMT = 30;

export enum DetailsFormFields {
  Name = "name"
}

export interface DetailsFormError {
  type: DetailsFormFields;
}

const ManageOrganisation = () => {
  const { orgId } = useParams();

  const {
    fetching,
    error,
    orgDescription,
    orgName,
    orgCounts: { admins, users, groups },
    enabled,
    usage,
    features,
    ssoStatus,
    saveOrgDetails,
    enableOrganisation,
    disableOrganisation,
    setFeatures,
    saving,
    saveError,
    saveErrorMsg,
    downloadUsage
  } = useManageOrganisation(orgId!);

  const navigate = useNavigate();

  const [name, setName] = useState(orgName);
  const [description, setDescription] = useState(orgDescription);
  const [selectedUsageOption, setSelectedUsageOption] = useState(
    UsageTimePeriod.ReportsRunPastYear
  );
  const [formErrors, setFormErrors] = useState<DetailsFormError[]>([]);
  const [saved, setSaved] = useState(false);
  const [isDownloadingUsage, setIsDownloadingUsage] = useState(false);

  const [isEnterOrgModalOpen, setIsEnterOrgModalOpen] = useState(false);

  const savedRef = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    setName(orgName);
    setDescription(orgDescription);
  }, [orgDescription, orgName, features]);

  if (error) {
    return (
      <S.ErrorFallback>
        <CloudOff size="50px" />
        <div>Something went wrong</div>
      </S.ErrorFallback>
    );
  }

  if (fetching) {
    return <LoadingPage />;
  }

  const onOrgNameChange = (value: string) => {
    if (value.length <= ORG_NAME_LIMT) {
      setName(value);
    }
  };

  const onOrgDescriptionChange = (value: string) => {
    setDescription(value);
  };

  const onSaveOrgDetails = async () => {
    setFormErrors([]);
    if (savedRef.current) {
      clearTimeout(savedRef.current);
    }

    const valid = name && name.length;

    if (!valid) {
      setFormErrors([{ type: DetailsFormFields.Name }]);
      return null;
    }

    const status = await saveOrgDetails(name, description, features);

    if (status) {
      setSaved(true);
      savedRef.current = setTimeout(() => setSaved(false), 2000);
    }
    return null;
  };

  const onDownloadUsage = () => {
    setIsDownloadingUsage(true);

    downloadUsage(() => setIsDownloadingUsage(false));
  };

  const toggleEnterOrgModal = () => {
    setIsEnterOrgModalOpen(prev => !prev);
  };

  const setXapienInsights = (value: boolean) => {
    setFeatures({ ...features, xapienInsights: value });
  };

  const setMfaEnabled = (value: boolean) => {
    setFeatures({ ...features, mfa: value });
  };

  const setAdverseMediaMonitoring = (value: boolean) => {
    setFeatures({ ...features, adverseMediaMonitoring: value });
  };

  const setSanctionMonitoring = (value: boolean) => {
    setFeatures({ ...features, sanctionMonitoring: value });
  };

  const onViewUsers = () => {
    navigate(`/hub/manage-organisation/${orgId}/users?orgName=${orgName}`);
  };

  const showMfaOption = ssoStatus === OrganisationSsoStatus.NotConfigured;

  return (
    <>
      <S.ManageOrganisation>
        <PageTitle title="Manage organisation" />
        <S.ManageOrganisationInnerContainer>
          <S.CustomTitleCard
            title={orgName}
            subtitle={orgDescription}
            icon={<S.BuildingIcon />}
          >
            <S.OrgDetails>
              {admins} admin{admins > 0 ? "s" : ""} • {users} user
              {users > 0 ? "s" : ""} • {groups} group{groups > 0 ? "s" : ""}
            </S.OrgDetails>
          </S.CustomTitleCard>
          <S.InputGroup>
            <Heading level={6}>Name</Heading>
            <Input
              placeholder="Enter name"
              onChange={onOrgNameChange}
              value={name}
              autoComplete="off"
            />
            {formErrors.some(
              formError => formError.type === DetailsFormFields.Name
            ) && <ErrorBanner text="Organisation name is required." />}
          </S.InputGroup>

          <S.InputGroup>
            <Heading level={6}>Description (optional)</Heading>
            <Input
              placeholder="Enter description"
              onChange={onOrgDescriptionChange}
              value={description}
              autoComplete="off"
            />
          </S.InputGroup>

          <S.UsagePanel
            usage={usage[selectedUsageOption]}
            isDownloadingUsage={isDownloadingUsage}
            onDownloadUsage={onDownloadUsage}
            usageOptions={usage}
            selectedUsageOption={selectedUsageOption}
            onUsageOptionSelect={value =>
              setSelectedUsageOption(value as UsageTimePeriod)
            }
          />

          <S.FeatureSection>
            <S.FeatureSectionItem>
              <S.FeatureSectionDetails>
                <Heading level={5}>Insights</Heading>
                <S.FeatureSectionDescription>
                  Insights is a non-standard feature and under development.
                  Admins must enable this service on a per user basis.
                </S.FeatureSectionDescription>
              </S.FeatureSectionDetails>

              <S.FeatureSectionAction>
                <Switch
                  checked={features.xapienInsights}
                  onChange={e => setXapienInsights(e.target.checked)}
                />
              </S.FeatureSectionAction>
            </S.FeatureSectionItem>
          </S.FeatureSection>

          <S.FeatureSection>
            <S.FeatureSectionItem>
              <S.FeatureSectionDetails>
                <Heading level={5}>Adverse Media Monitoring</Heading>
                <S.FeatureSectionDescription>
                  When switched on, account Admins can enable Adverse Media
                  Monitoring for Organisations.
                </S.FeatureSectionDescription>
              </S.FeatureSectionDetails>

              <S.FeatureSectionAction>
                <Switch
                  checked={features.adverseMediaMonitoring}
                  onChange={e => setAdverseMediaMonitoring(e.target.checked)}
                />
              </S.FeatureSectionAction>
            </S.FeatureSectionItem>
            <S.FeatureSectionItem>
              <S.FeatureSectionDetails>
                <Heading level={5}>Sanctions Monitoring</Heading>
                <S.FeatureSectionDescription>
                  When switched on, account Admins can enable Sanctions
                  Monitoring for Organisations.
                </S.FeatureSectionDescription>
              </S.FeatureSectionDetails>

              <S.FeatureSectionAction>
                <Switch
                  checked={features.sanctionMonitoring}
                  onChange={e => setSanctionMonitoring(e.target.checked)}
                />
              </S.FeatureSectionAction>
            </S.FeatureSectionItem>
          </S.FeatureSection>

          {showMfaOption && (
            <S.FeatureSection>
              <S.FeatureSectionItem>
                <S.FeatureSectionDetails>
                  <Heading level={5}>Multi-factor authentication (MFA)</Heading>
                  <S.FeatureSectionDescription>
                    When switched on, account Admins can enable MFA for users.
                    Once enables, MFA requires all users to enroll and setup the
                    secure login process.
                  </S.FeatureSectionDescription>
                </S.FeatureSectionDetails>

                <S.FeatureSectionAction>
                  <Switch
                    checked={features.mfa}
                    onChange={e => setMfaEnabled(e.target.checked)}
                  />
                </S.FeatureSectionAction>
              </S.FeatureSectionItem>
            </S.FeatureSection>
          )}

          {saveError && <ErrorBanner text={saveErrorMsg!} />}
        </S.ManageOrganisationInnerContainer>
        <S.Controls>
          <S.LeftControls>
            <S.Button
              text="Back"
              size={ButtonSize.Medium}
              type={ButtonType.Outlined}
              onClick={() => navigate(`/${routes.hub}`)}
            />
            <S.Button
              text="Enter organisation"
              size={ButtonSize.Medium}
              type={ButtonType.Outlined}
              onClick={toggleEnterOrgModal}
              IconLeading={User}
            />
            <S.Button
              text="View users and reports"
              size={ButtonSize.Medium}
              type={ButtonType.Outlined}
              onClick={onViewUsers}
              IconLeading={Eye}
            />
            {enabled ? (
              <S.Button
                text="Disable organisation"
                size={ButtonSize.Medium}
                type={ButtonType.Outlined}
                onClick={disableOrganisation}
                disabled={saving}
                IconLeading={Trash2}
                IconTrailing={
                  saving
                    ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
                    : undefined
                }
              />
            ) : (
              <S.Button
                text="Enable organisation"
                size={ButtonSize.Medium}
                type={ButtonType.Outlined}
                onClick={enableOrganisation}
                disabled={saving}
                IconLeading={Power}
                IconTrailing={
                  saving
                    ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
                    : undefined
                }
              />
            )}
          </S.LeftControls>
          <S.Button
            text={saved ? "Saved" : "Done"}
            size={ButtonSize.Medium}
            type={ButtonType.Filled}
            onClick={onSaveOrgDetails}
            disabled={saving}
            IconTrailing={
              saving
                ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
                : undefined
            }
          />
        </S.Controls>
      </S.ManageOrganisation>
      <EnterOrganisationModal
        orgName={orgName}
        orgDescription={orgDescription}
        orgId={orgId!}
        isOpen={isEnterOrgModalOpen}
        toggleOpen={toggleEnterOrgModal}
      />
    </>
  );
};

export default ManageOrganisation;
