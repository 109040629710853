import React, { FC, SVGProps } from "react";

import { Spinner } from "reactstrap";
import {
  useInsightReport,
  InsightReportActions
} from "util/hooks/useInsightReport";

import { InsightReportStatus } from "util/hooks/useInsightReport/types";

import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import { RotateCw } from "react-feather";

import Heading from "components/atoms/Heading";
import config from "config";
import S from "./styles";

const ErrorReport = () => {
  const { state, dispatch } = useInsightReport();

  const onRegenerateReport = () => {
    dispatch({ type: InsightReportActions.regenerateReport });
  };

  const isLoading = state.status === InsightReportStatus.regeneratingReport;

  return (
    <S.ErrorContainer>
      <S.ErrorLogoContainer>
        <S.ErrorLogo />
      </S.ErrorLogoContainer>
      <Heading level={3}>We&apos;ve hit a snag</Heading>
      <p>
        Xapien has been unable to generate Insights content relating to this
        subject.
        <br />
        Please re-run the report or contact{" "}
        <a href={`mailto:${config.supportEmail}`}>Customer Success</a> for
        assistance.
      </p>

      <S.RegenerateButton
        onClick={onRegenerateReport}
        type={ButtonType.Outlined}
        size={ButtonSize.Medium}
        disabled={isLoading}
        text={isLoading ? "Regenerating" : "Regenerate"}
        IconTrailing={
          isLoading
            ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
            : RotateCw
        }
      />
    </S.ErrorContainer>
  );
};

export default ErrorReport;
