import React, { CSSProperties, ReactNode } from "react";
import Heading from "components/atoms/Heading";

import { X, Zap } from "react-feather";
import S from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  onExitClick?: () => void;
  children: ReactNode;
  icon?: boolean;
  title?: string;
  width?: number;
  style?: CSSProperties;
  className?: string;
  variant?: Variant;
  fade?: boolean;
  noGap?: boolean;
}

export enum Variant {
  LIGHT = "light",
  DARK = "dark"
}

const ModalContainer = ({
  isOpen,
  toggleOpen,
  children,
  onExitClick,
  title,
  width,
  style,
  className,
  icon = false,
  variant = Variant.DARK,
  fade = true,
  noGap = false
}: Props) => {
  return (
    <S.Modal
      fade={fade}
      centered
      isOpen={isOpen}
      toggle={toggleOpen}
      width={width}
      style={style}
      variant={variant}
      className={className}
      noGap={noGap}
    >
      {onExitClick && variant === Variant.DARK && (
        <S.ExitButton onClick={onExitClick}>
          <X />
        </S.ExitButton>
      )}
      {title && variant === Variant.LIGHT && (
        <S.LightHeading level={4}>
          {icon && <Zap />}
          {title}
          {onExitClick && (
            <S.LightHeadingIconContainer>
              <X onClick={onExitClick} />
            </S.LightHeadingIconContainer>
          )}
        </S.LightHeading>
      )}
      {title && variant === Variant.DARK && (
        <Heading level={4}>
          {icon && <Zap />} {title}
        </Heading>
      )}
      {variant === Variant.DARK && children}
      {variant === Variant.LIGHT && <S.ModalContent>{children}</S.ModalContent>}
    </S.Modal>
  );
};

export default ModalContainer;
