import React, {
  useMemo,
  useReducer,
  useCallback,
  useEffect,
  FC,
  ReactNode
} from "react";

import {
  confirmSignIn,
  fetchMFAPreference,
  signIn,
  signInWithRedirect,
  signOut,
  updateMFAPreference,
  verifyTOTPSetup
} from "aws-amplify/auth";
import { apm } from "@elastic/apm-rum";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import dayjs from "dayjs";
import config from "config";

import { OpenAPI as InsightsOpenAPI } from "api/insights";
import {
  ApiError,
  IdentityUserApiService,
  OpenAPI as PortalOpenAPI
} from "api/portal";
import { OpenAPI as ArticleViewerOpenAPI } from "api/article-viewer";
import UserApi from "api/user";
import { UserPermissionsResultType } from "api/user/types";
import useHubAdminRole from "util/hooks/useHubAdminRole";
import { HUB_ASSUMED_ROLE_TOKEN_KEY } from "util/hooks/useHubAdminRole/provider";
import { routes } from "pages/Router/config";

import {
  AuthenticationConfig,
  apiToken,
  getAuthenticatedUser,
  setAuthenticationConfig
} from "util/authentication";

import {
  initialState,
  authenticationReducer,
  AuthenticationContext
} from "./context";

import {
  AuthenticationStatus,
  AuthenticationAction,
  AuthenticationActions
} from "./types";

interface Props {
  loginPath: string;
  ssoCallbackPath: string;
  children: ReactNode;
}

PortalOpenAPI.TOKEN = apiToken;
InsightsOpenAPI.TOKEN = apiToken;
ArticleViewerOpenAPI.TOKEN = apiToken;

export const AuthenticationContextProvider: FC<Props> = ({
  children,
  loginPath,
  ssoCallbackPath
}) => {
  setAuthenticationConfig(AuthenticationConfig.PORTAL);

  const isBlacklistedPath =
    window.location.pathname !== "/getting-started" &&
    window.location.pathname !== "/reset-password" &&
    !window.location.pathname.includes("/share");

  const location = useLocation();

  const { setIsAuthenticatedAsHubAdmin, isAuthenticatedAsHubAdmin } =
    useHubAdminRole();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [state, reducerDispatch] = useReducer(
    authenticationReducer,
    initialState
  );

  const dispatch = useCallback((action: AuthenticationAction) => {
    reducerDispatch(action);
  }, []);

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  const unauthenticateAndSignOut = useCallback(
    async (error?: string) => {
      await signOut();
      dispatch({
        type: AuthenticationActions.unauthenticated,
        error
      });
    },
    [dispatch]
  );

  const getHubAdminAuthenticationStatus = useCallback(() => {
    const hubAuthed = sessionStorage.getItem(HUB_ASSUMED_ROLE_TOKEN_KEY);

    if (hubAuthed) {
      const authObject = JSON.parse(hubAuthed);
      // If the token is expired
      if (
        dayjs(new Date()).add(authObject.expires, "s").toDate() < new Date()
      ) {
        sessionStorage.removeItem(HUB_ASSUMED_ROLE_TOKEN_KEY);
        navigate(routes.hub);
        return false;
      }
      // Override OpenAPI token config
      InsightsOpenAPI.TOKEN = authObject.token;
      PortalOpenAPI.TOKEN = authObject.token;
      ArticleViewerOpenAPI.TOKEN = authObject.token;
      return true;
    }

    return false;
  }, [navigate]);

  useEffect(() => {
    if (state.status === AuthenticationStatus.authenticated) {
      const api = new UserApi();

      const getPermissions = async () => {
        try {
          const permissionsResult = await api.getUserPermissions();
          if (permissionsResult.type === UserPermissionsResultType.Success) {
            dispatch({
              type: AuthenticationActions.updatePermissions,
              permissions: permissionsResult.permissions
            });
          } else {
            const { message } = permissionsResult;
            console.error("Error loading user permissions", { message });
            await unauthenticateAndSignOut(message);
          }
        } catch (e: unknown) {
          apm.captureError(e as Error);
          let message: string | undefined;
          if (e instanceof ApiError && e.body && e.body.message) {
            message = e.body.message;
          }
          try {
            await signOut();
          } catch (e2) {
            apm.captureError(e2 as Error);
            console.error("Fallback sign-out error", { e, e2 });
          }
          await unauthenticateAndSignOut(message);
        }
      };

      getPermissions();
    }
  }, [state.status, dispatch, unauthenticateAndSignOut]);

  useEffect(() => {
    if (state.status === AuthenticationStatus.unknown) {
      if (getHubAdminAuthenticationStatus()) {
        if (!isAuthenticatedAsHubAdmin) {
          setIsAuthenticatedAsHubAdmin(true);
        }
        dispatch({
          type: AuthenticationActions.authenticated,
          username: state.username
        });
        return;
      }

      getAuthenticatedUser()
        .then(async ({ session, username }) => {
          const isSingleSignOn = !!session?.idToken?.payload.identities;
          if (isSingleSignOn) {
            dispatch({
              type: AuthenticationActions.authenticated,
              username
            });
            return;
          }

          const mfaRequired = session?.idToken?.payload.mfaRequired;

          const { enabled } = await fetchMFAPreference();
          const totpIsEnabled = enabled?.includes("TOTP");

          if (mfaRequired === "true" && !totpIsEnabled) {
            await unauthenticateAndSignOut();

            if (location.pathname !== loginPath) {
              navigate(loginPath);
            }
          } else {
            dispatch({
              type: AuthenticationActions.authenticated,
              username
            });
          }
        })
        .catch(async () => {
          if (isBlacklistedPath && location.pathname !== loginPath) {
            navigate(loginPath, {
              state: { redirect: `${location.pathname}${location.search}` }
            });
          }
          // no need for error message - probably just not yet authenticated
          dispatch({ type: AuthenticationActions.unauthenticated });
        });
    }
  }, [
    state,
    dispatch,
    navigate,
    isBlacklistedPath,
    location.pathname,
    location.search,
    loginPath,
    setIsAuthenticatedAsHubAdmin,
    isAuthenticatedAsHubAdmin,
    getHubAdminAuthenticationStatus,
    unauthenticateAndSignOut
  ]);

  useEffect(() => {
    if (state.status === AuthenticationStatus.verifying) {
      if (!state.password) {
        dispatch({ type: AuthenticationActions.fetchLoginInfo });
        return;
      }

      // Try-catch is also needed here because Amplify will throw an error if
      // login is already pending. Otherwise, it will return a promise.
      try {
        signIn({
          username: `${state.email.toLowerCase()}+${config.tenantId}`,
          password: state.password
        })
          .then(async signInOutput => {
            const {
              nextStep: { signInStep }
            } = signInOutput;

            if (signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
              dispatch({
                type: AuthenticationActions.passwordVerified,
                mfaStatus: "required"
              });
            } else {
              const { session, username } = await getAuthenticatedUser();
              const mfaRequired =
                session?.idToken?.payload.mfaRequired === "true";

              if (mfaRequired) {
                dispatch({
                  type: AuthenticationActions.passwordVerified,
                  mfaStatus: "setup"
                });
              } else {
                dispatch({
                  type: AuthenticationActions.authenticated,
                  username
                });
              }
            }
          })
          .then(() => {
            dispatch({
              type: AuthenticationActions.resetState
            });
          })
          .catch(async ({ message: error }) => {
            await unauthenticateAndSignOut(error);
          });
      } catch (e) {
        apm.captureError(e as Error);
        console.error(e);
      }
    }
  }, [
    state,
    dispatch,
    navigate,
    searchParams,
    loginPath,
    unauthenticateAndSignOut
  ]);

  useEffect(() => {
    if (state.status === AuthenticationStatus.fetchingLoginInfo) {
      IdentityUserApiService.postUsersLoginInfo({
        requestBody: {
          email: state.email,
          tenantId: config.tenantId
        }
      })
        .then(res => {
          dispatch({
            type: AuthenticationActions.fetchLoginInfoSuccess,
            loginProvider: res.loginProvider ?? undefined
          });
        })
        .catch(e => {
          apm.captureError(e);
          dispatch({
            type: AuthenticationActions.fetchLoginInfoSuccess,
            loginProvider: undefined
          });
        });
    }
  }, [dispatch, state.status, state.email]);

  useEffect(() => {
    if (state.status === AuthenticationStatus.authenticating) {
      if (state.loginProvider) {
        signInWithRedirect({
          provider: {
            custom: state.loginProvider
          }
        });
        return;
      }

      confirmSignIn({
        challengeResponse: state.mfaCode
      })
        .then(() => {
          dispatch({
            type: AuthenticationActions.authenticated,
            username: state.username
          });
        })
        .catch(async ({ message: error }) => {
          await unauthenticateAndSignOut(error);
        });

      return;
    }

    if (state.status === AuthenticationStatus.mfaSetup) {
      verifyTOTPSetup({ code: state.mfaCode })
        .then(async () => {
          await updateMFAPreference({ totp: "PREFERRED" });
          dispatch({
            type: AuthenticationActions.authenticated,
            username: state.username
          });
        })
        .catch(async ({ message: error }) => {
          await unauthenticateAndSignOut(error);
        });

      return;
    }

    if (state.status === AuthenticationStatus.authenticated) {
      if (
        window.location.pathname === loginPath ||
        window.location.pathname === ssoCallbackPath
      ) {
        if (location.state?.redirect) {
          navigate(location.state.redirect);
        } else {
          navigate("/search");
        }
      }
    }

    if (
      state.status === AuthenticationStatus.unauthenticated &&
      window.location.pathname !== loginPath &&
      isBlacklistedPath
    ) {
      if (!getHubAdminAuthenticationStatus()) {
        navigate(loginPath);
      }
    }
  }, [
    state,
    dispatch,
    navigate,
    searchParams,
    isBlacklistedPath,
    location.state?.redirect,
    loginPath,
    ssoCallbackPath,
    isAuthenticatedAsHubAdmin,
    getHubAdminAuthenticationStatus,
    unauthenticateAndSignOut
  ]);

  return (
    <AuthenticationContext.Provider value={providerValue}>
      {children}
    </AuthenticationContext.Provider>
  );
};
