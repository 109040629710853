import React, { FC, useContext, useState } from "react";

import { reportStore } from "state/Store";
import { TimelineEvent } from "api/insights";
import useViewerMode from "util/hooks/useViewerMode";

import SourceInspector from "components/organisms/InsightReport/SourceInspector";
import RiskTags from "components/organisms/InsightReport/TimelineSection/Event/RiskTags";
import { InsightsTimelineStatus } from "util/hooks/useInsightsTimeline/types";
import { useInsightsTimeline } from "util/hooks/useInsightsTimeline";
import { isPDX } from "static-config";

import DisabledInsightsModal from "components/molecules/DisabledInsightsModal";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import usePdxDiagnosticsMode from "util/hooks/usePdxDiagnosticsMode";
import { getEventDuration } from "./utils";
import S from "./styles";

export interface EventContentProps {
  alignment?: string;
  nodeSize?: number;
  handleToggleDeleteModel?: (event: TimelineEvent) => void;
  event: TimelineEvent;
}

const EventContent: FC<EventContentProps> = ({
  alignment,
  nodeSize,
  handleToggleDeleteModel,
  event
}) => {
  const { isViewerModeEnabled } = useViewerMode();
  const shouldRenderWithSourcing =
    handleToggleDeleteModel && nodeSize && alignment;
  const diagnosticsModeEnabled = useContext(DiagnosticsModeContext).enabled;
  const { enabled: pdxDiagnosticsEnabled } = usePdxDiagnosticsMode();
  const [isDisabledInsightsModalShowing, setIsDisabledInsightsModalShowing] =
    useState(false);

  const isDisabled = isPDX;

  const getSourcesTitle = () => {
    const count = event.sources.length;
    if (count === 1) {
      return "1 Source";
    }

    return `${count} Sources`;
  };

  const { state: timelineState } = useInsightsTimeline();

  const sources = event.sources
    .filter(source => source.wam_source_ids.length > 0)
    .map(source =>
      reportStore.webAndMediaData.find(
        wam => wam.sourceId === source.wam_source_ids[0]
      )
    )
    // Remove the subHeading as we don't want to show that,
    // with the generic component we're using.
    .map(source => ({ ...source, subHeading: null }));

  // For the delete modal so that we take styles but don't show the delete button/ source inspector
  if (!shouldRenderWithSourcing) {
    return (
      <>
        <S.EventTitle>{event.title}</S.EventTitle>
        <S.EventSubtitle>
          <S.SourcesSubtitle>{getSourcesTitle()}:</S.SourcesSubtitle>{" "}
          {getEventDuration(event.sources.map(source => source.date))}
        </S.EventSubtitle>
        <S.DeleteModalEventContent>
          {event.description}
        </S.DeleteModalEventContent>
      </>
    );
  }

  const handleDeleteOnClick = (e: any) => {
    e.stopPropagation();
    if (isDisabled) {
      setIsDisabledInsightsModalShowing(true);
      return;
    }
    handleToggleDeleteModel(event);
  };

  const handleMouseOver = () => {
    if (!isPDX) {
      return;
    }
    setTimeout(() => {
      setIsDisabledInsightsModalShowing(true);
    }, 500);
  };

  const canDeleteEvent = !isViewerModeEnabled;

  return (
    <S.ContentDiv alignment={alignment} nodeSize={nodeSize}>
      <SourceInspector
        title={getSourcesTitle()}
        sources={sources}
        display="block"
      >
        <S.EventTitle>{event.title}</S.EventTitle>
        <S.EventSubtitle>
          <S.SourcesSubtitle>{getSourcesTitle()}:</S.SourcesSubtitle>{" "}
          {getEventDuration(event.sources.map(source => source.date))}
        </S.EventSubtitle>
        <S.EventContent alignment={alignment}>
          {event.description}
        </S.EventContent>
        <RiskTags risks={event.risk_categories} />
        {canDeleteEvent && (
          <S.DeleteButton
            alignment={alignment}
            nodeSize={nodeSize}
            disabled={
              timelineState.status === InsightsTimelineStatus.deletingEvent
            }
            onClick={handleDeleteOnClick}
            onMouseEnter={handleMouseOver}
            aria-label="Delete Selection Toolbar Button"
          >
            <S.TrashIcon disabled={isDisabled} />
          </S.DeleteButton>
        )}
      </SourceInspector>
      {isDisabledInsightsModalShowing &&
        isPDX &&
        !diagnosticsModeEnabled &&
        !pdxDiagnosticsEnabled && (
          <S.WrapperForModal
            alignment={alignment}
            onMouseLeave={() => setIsDisabledInsightsModalShowing(false)}
          >
            <DisabledInsightsModal
              title="Deleting events"
              onClose={() => setIsDisabledInsightsModalShowing(false)}
            />
          </S.WrapperForModal>
        )}
    </S.ContentDiv>
  );
};

export default EventContent;
