import React, { useRef } from "react";

import config from "config";
import { useFeatureEnabled, Features } from "services/features";
import { usePrintableReportState } from "util/hooks/usePrintableState";
import { PROFILE_CARD_TYPE } from "pages/report/sections/Profiles/utils";
import DowJonesDisclaimer from "pages/report/sections/DowJonesDisclaimer";
import XapienDisclaimer from "pages/report/sections/XapienDisclaimer";
import { REPORT_TYPES } from "util/reportTypes";
import { useVersion } from "services/version";
import WebAndMediaArticles from "components/organisms/WebAndMediaArticles";
import SectionErrorWrapper from "pages/report/SectionErrorWrapper";
import Profiles from "pages/report/sections/Profiles";
import RelatedOrganisations from "components/organisms/RelatedOrganisations";
import ReportHeader from "pages/report/ReportHeader";
import Footer from "components/atoms/Footer";
import { Section } from "pages/report/Section";
import DisregardedInformation from "pages/report/sections/WebAndMedia/DisregardedInformation";
import NotProcessedSection from "pages/report/sections/WebAndMedia/NotProcessedSection";
import { isReportVersionTooOldForScreening } from "util/screeningUtils";
import WebAndMediaArticlesFallback from "components/organisms/WebAndMediaArticles/WebAndMediaArticlesFallback";
import RelatedOrganisationsFallback from "components/organisms/RelatedOrganisations/RelatedOrganisationsFallback";
import ScreeningFallback from "components/organisms/ScreeningSection/ScreeningFallback";
import { orgReportSectionTitles } from "util/reportSectionTitles";
import { InfographicStateContextProvider } from "util/context/InfographicStateContext";
import Notes from "components/organisms/Notes";
import { UserNotesContextProvider } from "util/hooks/useUserNotes";
import { ENTITY_TYPE } from "pages/define/utils";

import ThemedScreening from "ThemedScreening";
import ThemedOrganisationOverview from "ThemedOrganisationOverview";
import { ReportStoreContextProvider } from "util/hooks/useReports";
import InsightReport from "components/organisms/InsightReport";
import { InsightsSection as OldInsightReport } from "components/organisms/InsightsSection";
import { showInsightReport } from "util/insightReportHelpers";
import { mutateToLowerCamelCase } from "util/common";
import { isPDX } from "static-config";
import { usePrintModeEnabled } from "util/hooks/useIsPrintModeEnabled";

const Report = props => {
  const {
    report,
    shellContainerRef,
    setAssessment,
    setAssessments,
    assessments,
    sectionCollapseStates,
    setCollapsed,
    imageSelection,
    setImageSelection,
    isReportRegenerationOpen,
    nameOnlySearch,
    contextsWithTypes,
    subjectType
  } = props;
  const haveNotProcessedSection = useFeatureEnabled(
    Features.NotProcessedSection
  );

  // Refs for scrolling to components
  const linkRefs = {
    webAndMedia: useRef(null),
    personalOverview: useRef(null),
    disregarded: useRef(null),
    notProcessed: useRef(null),
    keyPeople: useRef(null),
    organisationDetails: useRef(null),
    organisationStructure: useRef(null),
    relatedOrganisations: useRef(null),
    screening: useRef(null),
    notes: useRef(null),
    disclaimer: useRef(null)
  };
  const isPrintMode = usePrintModeEnabled();

  // Add state for each section here
  const [webAndMediaExpanded, setWebAndMediaExpanded] = usePrintableReportState(
    "web-and-media-section-expanded",
    true
  );
  const [keyPeopleExpanded, setKeyPeopleExpanded] = usePrintableReportState(
    "key-people-section-expanded",
    true
  );
  const [disregardedExpanded, setDisregardedExpanded] = usePrintableReportState(
    "Disregarded Information-section-expanded",
    false
  );
  const [notProcessedExpanded, setNotProcessedExpanded] =
    usePrintableReportState("Not Processed-section-expanded", false);

  const [screeningExpanded, setScreeningExpanded] = usePrintableReportState(
    "screening-section-expanded",
    true
  );

  const [relatedOrganisationsExpanded, setRelatedOrganisationsExpanded] =
    usePrintableReportState("related-organisations-section-expanded", true);

  const [notesExpanded, setNotesExpanded] = usePrintableReportState(
    "notes-section-expanded",
    isPrintMode
  );

  const [disclaimerExpanded, setDisclaimerExpanded] = usePrintableReportState(
    "disclaimer-section-expanded",
    true
  );

  const hasSourcesAndIsNotPDX = report.reportSourcing && !isPDX;

  const version = useVersion();
  const isVersionTooOldForScreening =
    isReportVersionTooOldForScreening(version);

  const sectionTitles = orgReportSectionTitles;

  // Add object for each section here
  const allSections = [
    {
      title: sectionTitles.ORGANISATION_DETAILS,
      linkRef: linkRefs.organisationDetails,
      expandable: false,
      defaultExpanded: true,
      reportTypes: [REPORT_TYPES.organisation]
    },
    {
      title: sectionTitles.NOTES,
      linkRef: linkRefs.notes,
      expandable: true,
      defaultExpanded: notesExpanded,
      setExpanded: val => setNotesExpanded(val)
    },
    !isVersionTooOldForScreening && {
      title: sectionTitles.SCREENING,
      linkRef: linkRefs.screening,
      expandable: true,
      defaultExpanded: screeningExpanded,
      setExpanded: val => setScreeningExpanded(val)
    },
    {
      title: sectionTitles.RELATED_ORGS,
      linkRef: linkRefs.relatedOrganisations,
      expandable: true,
      defaultExpanded: relatedOrganisationsExpanded,
      setExpanded: val => setRelatedOrganisationsExpanded(val)
    },
    {
      title: sectionTitles.SIGNIFICANT_PEOPLE,
      linkRef: linkRefs.keyPeople,
      expandable: true,
      defaultExpanded: keyPeopleExpanded,
      itemsToDisplayInitially: 4,
      setExpanded: val => setKeyPeopleExpanded(val)
    },
    {
      title: sectionTitles.WEB_AND_MEDIA,
      linkRef: linkRefs.webAndMedia,
      expandable: true,
      defaultExpanded: webAndMediaExpanded,
      setExpanded: val => setWebAndMediaExpanded(val)
    },
    config.tenant === "projectdx" && {
      title: sectionTitles.DISCLAIMER,
      linkRef: linkRefs.disclaimer,
      expandable: true,
      defaultExpanded: true
    },
    hasSourcesAndIsNotPDX && {
      title: sectionTitles.DISREGARDED,
      linkRef: linkRefs.disregarded,
      expandable: true,
      defaultExpanded: disregardedExpanded,
      setExpanded: val => setDisregardedExpanded(val)
    },
    haveNotProcessedSection &&
      hasSourcesAndIsNotPDX && {
        title: sectionTitles.NOT_PROCESSED,
        linkRef: linkRefs.notProcessed,
        defaultExpanded: notProcessedExpanded,
        setExpanded: val => setNotProcessedExpanded(val)
      }
  ];

  const renderWebAndMedia = () => {
    const snapshotData = report.snapshotSection ?? {
      media: {}
    };

    return (
      <SectionErrorWrapper
        forwardedRef={linkRefs.webAndMedia}
        title={sectionTitles.WEB_AND_MEDIA}
        isExpanded={webAndMediaExpanded}
        expandable
        setExpanded={val => setWebAndMediaExpanded(val)}
      >
        <Section
          ref={linkRefs.webAndMedia}
          title={sectionTitles.WEB_AND_MEDIA}
          isExpanded={webAndMediaExpanded}
          setExpanded={setWebAndMediaExpanded}
        >
          {report.reportSourcing?.relevantSourceGroups?.length ? (
            <ReportStoreContextProvider>
              <WebAndMediaArticles
                media={snapshotData.media}
                relevantSourceCount={report.reportSourcing?.relevantSourceCount}
                isReportRegenerationOpen={isReportRegenerationOpen}
              />
            </ReportStoreContextProvider>
          ) : (
            <WebAndMediaArticlesFallback />
          )}
        </Section>
      </SectionErrorWrapper>
    );
  };

  const renderKeyPeople = () => {
    return (
      <Profiles
        profileData={report.keyPeople?.keyPeopleList}
        ref={linkRefs.keyPeople}
        isExpanded={keyPeopleExpanded}
        setExpanded={val => setKeyPeopleExpanded(val)}
        title={sectionTitles.SIGNIFICANT_PEOPLE}
        nameOnlySearch={nameOnlySearch}
        profileType={PROFILE_CARD_TYPE.significantPeople}
        notShowingAllDirectors={report.keyPeople?.notShowingAllDirectors}
        isReportRegenerationOpen={isReportRegenerationOpen}
      />
    );
  };

  const renderRelatedOrganisations = () => {
    const confirmedCompanies =
      report?.relatedOrganisations?.confirmedCompanies ??
      report?.relatedOrganisations?.companies ??
      [];
    const sectionData = {
      confirmed: confirmedCompanies,
      unconfirmed: report?.relatedOrganisations?.unconfirmedCompanies ?? [],
      discarded: report?.relatedOrganisations?.discardedCompanies ?? [],
      confirmedCount:
        report?.relatedOrganisations?.totalConfirmedCompaniesCount ??
        confirmedCompanies.length,
      // backwards compat is only needed for confirmed count. If unconfirmed list is present, the count will be too
      unconfirmedCount:
        report?.relatedOrganisations?.totalUnconfirmedCompaniesCount,
      discardedCount:
        report?.relatedOrganisations?.totalDiscardedCompaniesCount,
      relatedLookup: report?.relatedOrganisations?.relatedCompaniesLookup ?? {}
    };

    const isRelatedOrganisationsPresent =
      sectionData.confirmed?.length ||
      sectionData.unconfirmed?.length ||
      sectionData.discarded?.length;

    return (
      <SectionErrorWrapper
        forwardedRef={linkRefs.relatedOrganisations}
        title={sectionTitles.RELATED_ORGS}
        isExpanded={relatedOrganisationsExpanded}
        expandable
        setExpanded={setRelatedOrganisationsExpanded}
      >
        <Section
          title={sectionTitles.RELATED_ORGS}
          ref={linkRefs.relatedOrganisations}
          isExpanded={relatedOrganisationsExpanded}
          setExpanded={setRelatedOrganisationsExpanded}
        >
          {isRelatedOrganisationsPresent ? (
            <RelatedOrganisations
              originalData={sectionData}
              isReportRegenerationOpen={isReportRegenerationOpen}
            />
          ) : (
            <RelatedOrganisationsFallback />
          )}
        </Section>
      </SectionErrorWrapper>
    );
  };

  const renderOrganisationDetails = () => {
    const orgOverviewData = report.organisationDetails;

    const sectionRefs = {};
    allSections.forEach(section => {
      sectionRefs[section?.title] = section?.linkRef;
    });

    const relatedOrgsMap = {};
    const relatedCompaniesLookup =
      report.relatedOrganisations?.relatedCompaniesLookup ?? {};
    const confirmedCompanies =
      report.relatedOrganisations?.confirmedCompanies ??
      report.relatedOrganisations?.companies ??
      [];
    Object.entries(relatedCompaniesLookup).forEach(([id, org]) => {
      relatedOrgsMap[id] = mutateToLowerCamelCase(org);
    });
    confirmedCompanies.forEach(org => {
      relatedOrgsMap[org.id] = org;
    });

    return (
      <SectionErrorWrapper
        forwardedRef={linkRefs.organisationDetails}
        title={sectionTitles.ORGANISATION_DETAILS}
        isExpanded
        expandable={false}
      >
        <InfographicStateContextProvider>
          <ReportStoreContextProvider>
            <ThemedOrganisationOverview
              {...orgOverviewData}
              sectionRefs={sectionRefs}
              relatedOrgsMap={relatedOrgsMap}
              title={sectionTitles.ORGANISATION_DETAILS}
              ref={linkRefs.organisationDetails}
            />
          </ReportStoreContextProvider>
        </InfographicStateContextProvider>
      </SectionErrorWrapper>
    );
  };

  const renderDisregardedAndNotProcessed = () => {
    const { reportSourcing } = report;
    const newReportSourcing = !!reportSourcing;
    if (!newReportSourcing) {
      return null;
    }
    return (
      <>
        {reportSourcing.nonRelevantSourceGroups &&
        reportSourcing.nonRelevantSourceGroups.length ? (
          <SectionErrorWrapper
            forwardedRef={linkRefs.disregarded}
            title={sectionTitles.DISREGARDED}
            isExpanded={disregardedExpanded}
            expandable
            setExpanded={val => setDisregardedExpanded(val)}
          >
            <DisregardedInformation
              title={sectionTitles.DISREGARDED}
              sourceCount={reportSourcing.nonRelevantSourceCount}
              sourceGroups={reportSourcing.nonRelevantSourceGroups}
              {...{
                setAssessment,
                setAssessments,
                assessments,
                sectionCollapseStates,
                setCollapsed,
                imageSelection,
                setImageSelection
              }}
              isExpanded={disregardedExpanded}
              setExpanded={val => setDisregardedExpanded(val)}
              ref={linkRefs.disregarded}
            />
          </SectionErrorWrapper>
        ) : null}
        <SectionErrorWrapper
          forwardedRef={linkRefs.notProcessed}
          title={sectionTitles.NOT_PROCESSED}
          isExpanded={notProcessedExpanded}
          expandable
          setExpanded={val => setNotProcessedExpanded(val)}
        >
          <NotProcessedSection
            title={sectionTitles.NOT_PROCESSED}
            sourceCount={reportSourcing.notProcessedSourceCount}
            sourceGroups={reportSourcing.notProcessedSourceGroups}
            {...{
              setAssessment,
              setAssessments,
              assessments,
              sectionCollapseStates,
              setCollapsed,
              imageSelection,
              setImageSelection
            }}
            isExpanded={notProcessedExpanded}
            setExpanded={val => setNotProcessedExpanded(val)}
            ref={linkRefs.notProcessed}
          />
        </SectionErrorWrapper>
      </>
    );
  };

  const renderScreening = () => {
    const isScreeningSectionPresent =
      (report.screeningSection ||
        report.dowSection ||
        report.dowOrgScreeningSection) &&
      !isVersionTooOldForScreening;

    return (
      <SectionErrorWrapper
        forwardedRef={linkRefs.screening}
        title={sectionTitles.SCREENING}
        isExpanded={screeningExpanded}
        expandable
        setExpanded={val => setScreeningExpanded(val)}
      >
        <Section
          title={sectionTitles.SCREENING}
          ref={linkRefs.screening}
          isExpanded={screeningExpanded}
          setExpanded={val => setScreeningExpanded(val)}
        >
          {isScreeningSectionPresent ? (
            <ThemedScreening
              screeningData={{
                default: report.screeningSection,
                dowSection: report.dowOrgScreeningSection ?? report.dowSection // Backward compat for older reports still using "dowSection"
              }}
              reportSubjectType={ENTITY_TYPE.Organisation}
              reportSubjectName={report.reportMetadata.subject}
              isReportRegenerationOpen={isReportRegenerationOpen}
            />
          ) : (
            <ScreeningFallback />
          )}
        </Section>
      </SectionErrorWrapper>
    );
  };

  const renderNotes = () => {
    return (
      <SectionErrorWrapper
        forwardedRef={linkRefs.notes}
        title={sectionTitles.NOTES}
        isExpanded={notesExpanded}
        setExpanded={val => setNotesExpanded(val)}
        expandable
      >
        <UserNotesContextProvider>
          <Notes
            title={sectionTitles.NOTES}
            ref={linkRefs.notes}
            isExpanded={notesExpanded}
            setExpanded={val => setNotesExpanded(val)}
          />
        </UserNotesContextProvider>
      </SectionErrorWrapper>
    );
  };

  const renderDisclaimer = () => {
    return (
      <SectionErrorWrapper
        forwardedRef={linkRefs.disclaimer}
        title={
          isPDX ? sectionTitles.PDXDISCLAIMER : sectionTitles.XAPIENDISCLAIMER
        }
        isExpanded={disclaimerExpanded}
        setExpanded={val => setDisclaimerExpanded(val)}
        expandable
      >
        <Section
          title={
            isPDX ? sectionTitles.PDXDISCLAIMER : sectionTitles.XAPIENDISCLAIMER
          }
          isExpanded={disclaimerExpanded}
          setExpanded={val => setDisclaimerExpanded(val)}
          ref={linkRefs.disclaimer}
        >
          {isPDX ? <DowJonesDisclaimer /> : <XapienDisclaimer />}
        </Section>
      </SectionErrorWrapper>
    );
  };

  const renderReportSections = () => {
    const isShowingInsightReport = report
      ? showInsightReport(report.preparedAtUtc)
      : false;

    return (
      <>
        {renderOrganisationDetails()}
        {isShowingInsightReport ? <InsightReport /> : <OldInsightReport />}
        {renderNotes()}
        {renderScreening()}
        {renderRelatedOrganisations()}
        {renderKeyPeople()}
        {renderWebAndMedia()}
        {renderDisregardedAndNotProcessed()}
        {renderDisclaimer()}
      </>
    );
  };

  const renderBody = () => {
    const searchErrorInfos = report.searchErrorInfo || [];
    const systemIssues = report.systemIssues || [];
    return (
      <>
        <ReportHeader
          menuItems={allSections}
          report={report}
          shellContainerRef={shellContainerRef}
          contextsWithTypes={contextsWithTypes}
          subjectType={subjectType}
        />

        <div className="report-inner-container">
          {renderReportSections()}
          <Footer
            systemIssues={systemIssues}
            searchErrorInfos={searchErrorInfos}
          />
        </div>
      </>
    );
  };

  return renderBody();
};

export default Report;
