import { useCallback, useEffect, useMemo, useState } from "react";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";

import Api from "api/tenant";
import { OrganisationFeatures, OrganisationSsoStatus } from "api/tenant/types";

export enum UsageTimePeriod {
  ReportsRunPast30Days = "Past 30 days",
  ReportsRunPast7Days = "Past 7 days",
  ReportsRunPastYear = "Past year",
  ReportsRunSinceOrganisationCreation = "Since account opened"
}

const defaultFeatures: OrganisationFeatures = {
  xapienInsights: false,
  adverseMediaMonitoring: false,
  sanctionMonitoring: false,
  mfa: false
};

const useManageOrganisation = (orgId: string) => {
  const [{ fetching, error }, dispatch] = useFetchReducer();
  const [
    { fetching: saving, error: saveError, errorMessage: saveErrorMsg },
    saveDispatch
  ] = useFetchReducer();
  const [orgName, setOrgName] = useState("");
  const [orgDescription, setOrgDescription] = useState("");
  const [orgCounts, setOrgCounts] = useState({
    admins: 0,
    users: 0,
    groups: 0
  });
  const [enabled, setEnabled] = useState(false);
  const [orgFeatures, setOrgFeatures] =
    useState<OrganisationFeatures>(defaultFeatures);
  const [usage, setUsage] = useState<Record<UsageTimePeriod, number>>({
    [UsageTimePeriod.ReportsRunPastYear]: 0,
    [UsageTimePeriod.ReportsRunPast30Days]: 0,
    [UsageTimePeriod.ReportsRunPast7Days]: 0,
    [UsageTimePeriod.ReportsRunSinceOrganisationCreation]: 0
  });
  const [ssoStatus, setSsoStatus] = useState<OrganisationSsoStatus>();

  const TenantApi = useMemo(() => new Api(), []);

  const initialiseOrgDetails = useCallback(async () => {
    dispatch({ type: RequestActions.SendRequest });

    const [detailsResponse, usageResponse] = await Promise.all([
      TenantApi.getOrganisationDetails(orgId),
      TenantApi.getOrganisationUsage(orgId)
    ]);

    if (!detailsResponse.status || !usageResponse.status) {
      dispatch({ type: RequestActions.SetError });
      return;
    }

    const { response: details } = detailsResponse;
    setOrgName(details?.name ?? "");
    setOrgDescription(details?.description ?? "");
    setOrgFeatures(details?.features ?? defaultFeatures);
    setOrgCounts({
      groups: details?.groups ?? 0,
      users: details?.users ?? 0,
      admins: details?.admins ?? 0
    });
    setEnabled(details?.enabled ?? false);
    setSsoStatus(details?.ssoStatus);

    const { response: usageStat } = usageResponse;
    setUsage({
      [UsageTimePeriod.ReportsRunPastYear]: usageStat?.reportsRunPastYear ?? 0,
      [UsageTimePeriod.ReportsRunPast30Days]:
        usageStat?.reportsRunPast30Days ?? 0,
      [UsageTimePeriod.ReportsRunPast7Days]:
        usageStat?.reportsRunPast7Days ?? 0,
      [UsageTimePeriod.ReportsRunSinceOrganisationCreation]:
        usageStat?.reportsRunSinceOrgCreated ?? 0
    });

    dispatch({ type: RequestActions.SetSuccess });
  }, [TenantApi, dispatch, orgId]);

  useEffect(() => {
    initialiseOrgDetails();
  }, [initialiseOrgDetails]);

  const saveOrgDetails = async (
    name: string,
    description: string,
    features: OrganisationFeatures
  ) => {
    saveDispatch({ type: RequestActions.SendRequest });

    const { status, message } = await TenantApi.updateOrganisationDetails(
      orgId,
      name,
      description
    );

    if (!status) {
      saveDispatch({
        type: RequestActions.SetError,
        errorMessage: message ?? "Something went wrong. Try again in a moment."
      });
      return status;
    }

    const featuresResult = await TenantApi.updateOrganisationFeatures(
      orgId,
      features
    );
    if (!featuresResult.status) {
      saveDispatch({
        type: RequestActions.SetError,
        errorMessage:
          featuresResult.message ??
          "Something went wrong. Try again in a moment."
      });
      return status;
    }

    saveDispatch({ type: RequestActions.SetSuccess });
    return status;
  };

  const downloadUsage = (onFinish: () => void) => {
    TenantApi.downloadOrganisationUsage(orgId).then(result => {
      if (result.status && result.response) {
        const anchor = document.createElement("a");
        anchor.href = result.response;
        anchor.target = "_blank";

        anchor.click();
        onFinish();
      }
    });
  };

  const enableOrganisation = () => {
    saveDispatch({ type: RequestActions.SendRequest });
    TenantApi.enableOrganisation(orgId)
      .then(() => {
        saveDispatch({ type: RequestActions.SetSuccess });
        setEnabled(true);
      })
      .catch(message => {
        saveDispatch({
          type: RequestActions.SetError,
          errorMessage:
            message ?? "Something went wrong. Try again in a moment."
        });
      });
  };

  const disableOrganisation = () => {
    saveDispatch({ type: RequestActions.SendRequest });
    TenantApi.disableOrganisation(orgId)
      .then(() => {
        saveDispatch({ type: RequestActions.SetSuccess });
        setEnabled(false);
      })
      .catch(message => {
        saveDispatch({
          type: RequestActions.SetError,
          errorMessage:
            message ?? "Something went wrong. Try again in a moment."
        });
      });
  };

  return {
    fetching,
    error,
    orgName,
    orgDescription,
    orgCounts,
    enabled,
    usage,
    features: orgFeatures,
    ssoStatus,
    saveOrgDetails,
    enableOrganisation,
    disableOrganisation,
    setEnabled,
    setFeatures: setOrgFeatures,
    saving,
    saveError,
    saveErrorMsg,
    downloadUsage
  };
};

export default useManageOrganisation;
