// routes are here because if in the Router file it will break the tests
// for all portions of the app as it cannot compile a third party library
export const routes = {
  signin: "sign-in",
  diagnostics: "diagnostics",
  report: "report",
  preparing: "preparing",
  permalink: "permalink",
  reports: "reports",
  search: "search",
  users: "users",
  groups: "groups",
  gettingStarted: "getting-started",
  share: "share",
  resetPassword: "reset-password",
  login: "login",
  ssoCallback: "sso/callback",
  hubLogin: "login",
  hub: "hub",
  createOrg: "create-organisation",
  manageOrg: "manage-organisation",
  hubOrganisationList: "organisations",
  insightReport: "insight-report",
  articleViewer: "article-viewer"
};
