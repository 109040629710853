import React from "react";

import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import Heading from "components/atoms/Heading";

import { Mail, Info } from "react-feather";

import S from "./styles";

const ErrorLoadingSources = () => {
  const onContactSupport = () => {
    window.open(`mailto:support@xapien.com`, "_blank", "noopener,noreferrer");
  };

  return (
    <S.Container>
      <S.LogoContainer>
        <Info />
      </S.LogoContainer>
      <Heading level={5}>We hit a snag with this source</Heading>

      <p>
        We’ve run into some trouble generating the sourcing for this
        information. You should treat this information with caution, unless you
        can verify it with other sources
      </p>

      <S.Button
        onClick={onContactSupport}
        type={ButtonType.Filled}
        size={ButtonSize.Medium}
        text="Customer Success"
        IconTrailing={Mail}
      />
    </S.Container>
  );
};

export default ErrorLoadingSources;
