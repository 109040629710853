import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  useSearchParams
} from "react-router-dom";

// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import { ApmRoutes } from "@elastic/apm-rum-react";

// Shells
import GlobalShell from "pages/shell/GlobalShell";
import PortalShell from "pages/shell/PortalShell";
import HubShell from "pages/shell/HubShell";
import ArticleViewerShell from "pages/shell/ArticleViewerShell";

// Portal pages
import Reports from "pages/Portal/Reports";
import Users from "pages/Portal/Users";
import Groups from "pages/Portal/Groups";
import GettingStarted from "pages/Portal/GettingStarted";
import Login from "pages/Portal/Login";
import SsoCallback from "pages/Portal/SsoCallback";
import SharedReportContainer from "pages/Portal/SharedReportContainer";
import ResetPassword from "pages/Portal/ResetPassword";
import Search from "pages/Portal/Search";
import GroupReports from "pages/Portal/GroupReports";
import UserReports from "pages/Portal/UserReports";
import InsightReport from "pages/Portal/InsightReport";

// Hub pages
import HubLogin from "pages/Hub/Login";
import ManageOrganisation from "pages/Hub/ManageOrganisation";
import CreateOrganisation from "pages/Hub/CreateOrganisation";
import HubOrganisationList from "pages/Hub/OrganisationList";
import HubResetPassword from "pages/Hub/ResetPassword";
import OrganisationUsersList from "pages/Hub/OrganisationUsersList";
import OrganisationUserReportsList from "pages/Hub/OrganisationUserReportsList";

// Misc
import ArticleViewerWrapper from "pages/ArticleViewer/ArticleViewerWrapper";
import PreparingReportContainer from "pages/report/containers/PreparingReportContainer";
import InviteConfirmationModal from "components/organisms/InviteConfirmationModal";
import Diagnostics from "pages/diagnostics";
import ReportContainer from "pages/report";

import { routes } from "./config";

declare global {
  interface Window {
    PUBLIC_URL: string;
  }
}

const RequestHandler = () => {
  const [searchParams] = useSearchParams();

  if (searchParams.get("mode") === "inviteRequest") {
    return <InviteConfirmationModal />;
  }

  return <Navigate to={routes.search} replace />;
};

const Router = () => {
  return (
    <BrowserRouter basename={window.PUBLIC_URL}>
      <ApmRoutes>
        <Route element={<GlobalShell />}>
          <Route path={routes.hub} element={<HubShell />}>
            <Route
              index
              element={<Navigate to={routes.hubOrganisationList} replace />}
            />
            <Route
              path="*"
              element={<Navigate to={routes.hubOrganisationList} replace />}
            />
            <Route
              path={routes.hubOrganisationList}
              element={<HubOrganisationList />}
            />
            <Route path={routes.hubLogin} element={<HubLogin />} />
            <Route
              path={`${routes.manageOrg}/:orgId`}
              element={<ManageOrganisation />}
            />
            <Route
              path={`${routes.manageOrg}/:orgId/${routes.users}`}
              element={<OrganisationUsersList />}
            />
            <Route
              path={`${routes.manageOrg}/:orgId/${routes.users}/:userId/${routes.reports}`}
              element={<OrganisationUserReportsList />}
            />
            <Route path={routes.createOrg} element={<CreateOrganisation />} />
            <Route path={routes.resetPassword} element={<HubResetPassword />} />
          </Route>
          <Route element={<PortalShell />}>
            <Route index element={<RequestHandler />} />
            <Route path="*" element={<Navigate to={routes.search} replace />} />
            <Route path={routes.insightReport} element={<InsightReport />} />
            <Route path={routes.login} element={<Login />} />
            <Route path={routes.ssoCallback} element={<SsoCallback />} />
            <Route path={routes.resetPassword} element={<ResetPassword />} />
            <Route path={routes.gettingStarted} element={<GettingStarted />} />
            <Route path={routes.search} element={<Search />} />
            <Route path={routes.reports} element={<Reports />} />
            <Route path={routes.users} element={<Users />} />
            <Route path={routes.groups} element={<Groups />} />
            <Route
              path={`${routes.groups}/:groupId`}
              element={<GroupReports />}
            />
            <Route path={`${routes.users}/:userId`} element={<UserReports />} />
            <Route
              path={`${routes.groups}/:groupId`}
              element={<GroupReports />}
            />
            <Route
              path={`${routes.share}/:enquiryId`}
              element={<SharedReportContainer />}
            />
            <Route
              path={`${routes.diagnostics}/:enquiryId`}
              element={<Diagnostics />}
            />
            <Route path={routes.report}>
              <Route
                path={`${routes.preparing}/:enquiryId`}
                element={<PreparingReportContainer />}
              />
              <Route path=":enquiryId" element={<ReportContainer />} />
            </Route>
          </Route>
          <Route element={<ArticleViewerShell />}>
            <Route
              path={`${routes.articleViewer}/:provider/:articleId`}
              element={<ArticleViewerWrapper />}
            />
          </Route>
        </Route>
      </ApmRoutes>
    </BrowserRouter>
  );
};

export default Router;

export { routes };
